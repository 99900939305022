<template>
  <div class="operationPanel-setting" style="display: none">
    <!-- 用户信息 -->
    <div>用户名：{{ userInfo.username }}</div>
    <!--  退出登陆  -->
    <a-button
      type="primary"
      danger
      :style="{ marginTop: '8px' }"
      @click="logout"
    >
      退出登陆
    </a-button>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
import router from "@/router";
import { decodeToken } from "@/hooks/decodeToken";
import { ref } from "vue";
export default {
  name: "operationPanelSetting",
  props: {
    storeId: {
      type: Number,
      default: null,
    },
  },
  setup() {
    const logout = () => {
      // 删除 用户信息
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userInfo");
      // 跳转到登录页面
      router.push("/login").then(() => {
        // 提示信息
        message.success("已退出登录");
      });
    };
    const userInfo = ref(decodeToken());

    return { logout, userInfo };
  },
};
</script>

<style scoped lang="less">
.operationPanel-setting {
  height: 100%;
}
</style>
